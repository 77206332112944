import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, useMediaQuery } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { StrategyHistoryItem } from './components/StrategyHistoryItem';
import { SymbolList } from './components/SymbolList';
import { Strategy } from '../strategy/strategy-types';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import {
  changeStrategyAction,
  fetchStrategies,
  fetchStrategyHistories,
  updateStrategy,
} from '../strategy/strategy-actions';
import {
  changeStrategyStore,
  fetchStrategyHistoryEvent,
} from '../strategy/strategy-events';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { StrategyTime } from './components/StrategyTime';
import { Loading } from '../../shared/components/Loading';
import { useTheme } from '@mui/material/styles';

export const DEFAULT_STRATEGY_VALUE = {
  symbol: 'AUDJPY',
  favorMovement: 0,
  againstMovement: 0,
  redAgainst: 0,
  safetyLoss: 0,
  safetyProfit: 0,
  cycles: 0,
};
export const SettingStrategy: React.FC = () => {
  const { strategy } = useStore(changeStrategyStore);
  const [selected, setSelected] = useState<Strategy>(DEFAULT_STRATEGY_VALUE);
  const { strategyHistories } = useEvent(fetchStrategyHistoryEvent);
  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const [callFetchStrategyHistories] = useCallAction(fetchStrategyHistories, {
    onCompleted: () => setLoadingHistory(false),
  });

  const [callFetchStrategies] = useCallAction(fetchStrategies, {
    onCompleted: ({ strategies }) => {
      if (!strategy) {
        const defaultStrategy = strategies.length
          ? strategies[0]
          : DEFAULT_STRATEGY_VALUE;
        changeStrategyAction(defaultStrategy);
        setSelected(defaultStrategy);
      }
      setLoading(false);
    },
  });

  const [callUpdateStrategy, updateLoading] = useCallAction(updateStrategy, {
    onCompleted: ({ strategy: _strategy }) => {
      callFetchStrategies();
      callFetchStrategyHistories({ strategyId: _strategy?.id });
    },
  });

  useEffect(() => {
    callFetchStrategies();
    setLoading(true);
  }, [callFetchStrategies]);

  useEffect(() => {
    if (strategy && strategy.id) {
      setSelected(strategy);
      callFetchStrategyHistories({ strategyId: strategy?.id });
    }
  }, [callFetchStrategyHistories, strategy]);

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setSelected({
      ...selected,
      [event.target.name as keyof Strategy]: event.target.value,
    });
  };

  if (loading) return <Loading height='100%' />;

  const button = (
    <Box display={'flex'} alignItems={'center'}>
      <ButtonDefault
        isLoading={updateLoading}
        onClick={() =>
          callUpdateStrategy({
            id: selected?.id,
            symbol: selected?.symbol,
            favorMovement: parseFloat(selected?.favorMovement.toString()),
            safetyProfit: parseFloat(selected?.safetyProfit.toString()),
            safetyLoss: parseFloat(selected?.safetyLoss.toString()),
            redAgainst: parseFloat(selected?.redAgainst.toString()),
            againstMovement: parseFloat(selected?.againstMovement.toString()),
            cycles: parseInt(selected.cycles.toString()),
          })
        }
        style={{
          height: 40,
          textTransform: 'capitalize',
          background: '#D2FFCE',
          color: '#5D8F5A',
        }}
      >
        Save
      </ButtonDefault>
      <Box paddingLeft={1} display={'flex'} alignItems={'center'}>
        <AssignmentIcon fontSize='small' />
      </Box>
    </Box>
  );

  const textMediaQuery = isMd ? 5 : 4;
  const boxMediaQuery = isMd ? 7 : 8;

  return (
    <Box>
      <Grid container borderBottom={'1px solid #F0F0F0'} spacing={1}>
        <Grid item xs={12}>
          <StrategyTime />
        </Grid>
        <Grid item xs={12}>
          <SymbolList />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} mb={2}>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid
                  item
                  xs={textMediaQuery}
                  display='flex'
                  alignItems='center'
                >
                  <Typography paddingRight={1}>Favor Movement</Typography>
                </Grid>
                <Grid item xs={boxMediaQuery}>
                  <Box minWidth={55} position={'relative'}>
                    <TextField
                      name='favorMovement'
                      fullWidth
                      color={'success'}
                      size='small'
                      value={selected?.favorMovement}
                      inputProps={{
                        style: {
                          paddingRight: '20px',
                          paddingLeft: '1px!important',
                        },
                      }}
                      onChange={onChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid
                  item
                  xs={textMediaQuery}
                  display='flex'
                  alignItems='center'
                >
                  <Typography paddingRight={1}>Against Movement</Typography>
                </Grid>
                <Grid item xs={boxMediaQuery}>
                  <Box minWidth={55} position={'relative'}>
                    <TextField
                      name='againstMovement'
                      fullWidth
                      color={'success'}
                      size='small'
                      value={selected?.againstMovement}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 19 },
                      }}
                      inputProps={{
                        style: {
                          paddingRight: '20px',
                          paddingLeft: '1px!important',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              {button}
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid
                  item
                  xs={textMediaQuery}
                  display='flex'
                  alignItems='center'
                >
                  <Typography paddingRight={1}>Safety Profit</Typography>
                </Grid>
                <Grid item xs={boxMediaQuery}>
                  <Box minWidth={55} position={'relative'}>
                    <TextField
                      name='safetyProfit'
                      fullWidth
                      color={'success'}
                      size='small'
                      value={selected?.safetyProfit}
                      inputProps={{
                        style: {
                          paddingRight: '20px',
                          paddingLeft: '1px!important',
                        },
                      }}
                      onChange={onChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid
                  item
                  xs={textMediaQuery}
                  display='flex'
                  alignItems='center'
                >
                  <Typography paddingRight={1}>Safety Loss</Typography>
                </Grid>
                <Grid item xs={boxMediaQuery}>
                  <Box minWidth={55} position={'relative'}>
                    <TextField
                      name='safetyLoss'
                      fullWidth
                      color={'success'}
                      size='small'
                      value={selected?.safetyLoss}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 19 },
                      }}
                      inputProps={{
                        style: {
                          paddingRight: '20px',
                          paddingLeft: '1px!important',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Grid container>
                    <Grid display='flex' alignItems='center'>
                      <Typography paddingRight={1}>Cycles</Typography>
                    </Grid>
                    <Grid item>
                      <Box minWidth={55} position={'relative'} width={100}>
                        <TextField
                          name='cycles'
                          fullWidth
                          color={'success'}
                          size='small'
                          value={selected?.cycles}
                          inputProps={{
                            style: {
                              paddingRight: '20px',
                              paddingLeft: '1px!important',
                            },
                          }}
                          onChange={onChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container>
                    <Grid item display='flex' alignItems='center'>
                      <Typography paddingRight={1}>Red Against</Typography>
                    </Grid>
                    <Grid item>
                      <Box minWidth={55} position={'relative'} width={100}>
                        <TextField
                          name='redAgainst'
                          fullWidth
                          color={'success'}
                          size='small'
                          value={selected?.redAgainst}
                          inputProps={{
                            style: {
                              paddingRight: '20px',
                              paddingLeft: '1px!important',
                            },
                          }}
                          onChange={onChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
              {button}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loadingHistory ? (
        <div>Loading...</div>
      ) : (
        <Grid
          my={'20px'}
          px={'10px'}
          container
          style={{ height: 300, maxHeight: 300, overflow: 'auto' }}
        >
          {strategyHistories?.map((strategyHistoryItem, i) => (
            <Grid key={i} xs={12} item>
              <StrategyHistoryItem
                strategyHistoryItem={strategyHistoryItem}
                active={!i}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
