import React from 'react';
import { Box, Typography } from '@mui/material';
import { useMetaCloudHook } from '../../../modules/meta-api-cloud/meta-api-cloud-hooks';
// import { useGlobalSocket } from '../../../modules/socket/socket-hook';

export const ConnectionBroker: React.FC = () => {
  const { isConnectedToBroker } = useMetaCloudHook();
  // const { strategyStatus } = useGlobalSocket();

  return (
    <Box display={'flex'} justifyContent={'center'} flexDirection="column">
      <Typography
        color={'black'}
        fontSize={14}
        style={{ textTransform: 'capitalize' }}
      >
        <>
          <span
            style={{
              color: isConnectedToBroker ? '#4FD340' : 'red',
              paddingRight: 2,
            }}
          >
            {'●'}
          </span>
          Broker
        </>
      </Typography>
      {/*<Typography*/}
      {/*  color={'black'}*/}
      {/*  fontSize={14}*/}
      {/*  style={{ textTransform: 'capitalize' }}*/}
      {/*>*/}
      {/*  <>*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        color: strategyStatus ? '#4FD340' : 'red',*/}
      {/*        paddingRight: 2,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {'●'}*/}
      {/*    </span>*/}
      {/*    Strategy*/}
      {/*  </>*/}
      {/*</Typography>*/}
    </Box>
  );
};
