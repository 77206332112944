import React, { useState } from 'react';
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  Theme,
  ListItemButton,
  // Accordion,
  // AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import { SwipeableDrawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupIcon from '@mui/icons-material/Group';
// import { TradingVolumen } from './TradingVolumen';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints?.up('sm')]: {
        /**
         *
         * @param {object} props - Styles Props.
         * @param {boolean} props.drawerWidth - Drawer Width.
         * @returns {number} - Drawer Width.
         */
        flexShrink: 0,
        transition: theme.transitions.create(['width', 'transform'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    drawerPaper: {
      /**
       *
       * @param {object} props - Styles Props.
       * @param {boolean} props.drawerWidth - Drawer Width.
       * @returns {number} - Drawer Width.
       */
      padding: '28px 2px',
      alignItems: 'center',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    listItem: {
      minHeight: 56,
      borderRadius: 4,
      paddingLeft: 20,
      display: 'flex',
      marginBottom: theme.spacing(1),
      '&.Mui-selected': {
        color: '#808080',
        backgroundColor: 'rgba(128, 128, 128, 0.64)',
        '& .MuiListItemIcon-root': {
          color: '#808080',
        },
      },
      '& > .MuiListItemText-root > .MuiTypography-root': {
        fontWeight: '700!important',
      },
      '&:hover': {
        '&.Mui-selected': {
          color: '#808080',
          backgroundColor: 'rgba(128, 128, 128, 0.64)',
          '& .MuiListItemIcon-root': {
            color: '#808080',
          },
        },
      },
    },
  }),
);

const ROUTES = [
  {
    path: '/',
    name: 'Dashboard',
    icon: <HomeIcon fontSize="medium" />,
  },
  {
    path: '/order-activity',
    name: 'Activity',
    icon: <CallMissedOutgoingIcon fontSize="medium" />,
  },
  {
    path: '/order-history',
    name: 'History',
    icon: <AssessmentOutlinedIcon fontSize="medium" />,
  },
  {
    path: '/users',
    name: 'Users',
    icon: <GroupIcon fontSize="medium" />,
  },
  {
    path: '/setting/account',
    name: 'Settings',
    icon: <SettingsIcon fontSize="medium" />,
  },
  {
    path: '/pairs',
    name: 'Add a pair',
    icon: <AddCircleIcon fontSize="medium" />,
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: <LogoutIcon fontSize="medium" />,
  },
];

export const DrawerMenu: React.FC = () => {
  const [openDrawer, setOpen] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  // const [expanded, setExpanded] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="small" />
      </IconButton>
      <SwipeableDrawer
        anchor={'left'}
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ display: { md: 'none', xs: 'display' } }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <List style={{ width: '100%' }}>
            {/*<ListItemButton*/}
            {/*  className={classes.listItem}*/}
            {/*  onClick={() => setExpanded(!expanded)}*/}

            {/*  style={{ padding: '0 20px' }}*/}
            {/*>*/}
            {/*  <Accordion*/}

            {/*    expanded={expanded}*/}
            {/*  >*/}
            {/*    <AccordionSummary*/}
            {/*      expandIcon={<ArrowDropDownIcon />}*/}
            {/*      aria-controls='panel1-content'*/}
            {/*      id='panel1-header'*/}
            {/*    >*/}
            {/*      <Typography>Volumen</Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*      <TradingVolumen />*/}
            {/*    </AccordionDetails>*/}
            {/*  </Accordion>*/}

            {/*</ListItemButton>*/}

            {ROUTES.map((route, index) => (
              <ListItemButton
                className={classes.listItem}
                key={index}
                onClick={() => navigate(route.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 30,
                    mr: 0,
                    justifyContent: 'center',
                  }}
                >
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
};
